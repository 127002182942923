import React, { useState } from "react"
import Layout from "../components/layout"
import "react-h5-audio-player/lib/styles.css"
import FaqComponent from "../components/faq/faq-component"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import BreadCrumbs from "../components/bread-crumbs"
import "./services.scss"
import { isValidContentfulImage, isValidSectionField } from "../common/utils/CommonUtils"
import Button from "@material-ui/core/Button"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"

const breadCrumbsList = [

    {
        title: "Home",
        link: "/",
    },

]

export default function(props) {
    const [activeFaqIndex, setActiveFaqIndex] = useState(-1)
    const { data } = props
    const pageData = data.allContentfulServiceArchivePage.edges[0].node
    const {
        visibleServices,
        shortSectionsHeading,
        processHeading,
        shortSections,
        processItems,
        topSection,
        belowProcessText,
        letterToLovedOnes,
        faQsHeading,
        faqItems,
        servicesHeading,
        servicesSubtitle,
        cardsHeading,
        cards,
        articlesHeading,
        educationArticleLinks,
    } = pageData
    return (
        <Layout location={props.location}>

            <SEO title={topSection.title}/>
            {/*<BreadCrumbs currentPage={"Community Payment"} breadCrumbsList={breadCrumbsList}/>*/}
            <div className="get-started-home-wrapper">
                <div className="get-started-home-inner">
                    <div className="get-started-text-wrap">
                        <img className="get-started-home-pattern"
                             src={require(
                                 "../assets/images/home-bg-circles.png")}
                             alt="Pattern"/>
                        {
                            isValidSectionField(topSection, "title") &&
                            <h1 className="blackHeading2">{topSection.title}</h1>
                        }
                        {
                            isValidSectionField(topSection, "subtitle")
                            &&
                            <p className="subTitle"
                               dangerouslySetInnerHTML={{ __html: topSection.subtitle }}></p>
                        }
                        {
                            isValidSectionField(topSection, "description")
                            &&
                            <p className="parah"
                               dangerouslySetInnerHTML={{ __html: topSection.description.description }}></p>
                        }
                        <Button onClick={() => {

                        }}
                                href={"/get-started"}
                                className="blue-arrow-btn" color="primary">
                            Get Started <ArrowForwardIcon/>
                        </Button>
                    </div>
                    <div className="get-started-anim-wrap">
                        {
                            isValidContentfulImage(topSection.imageOrVideo)
                            && (
                                <img className="get-started-home-hero-img"
                                     src={topSection.imageOrVideo?.file?.url}
                                     alt="providerImg"/>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="new-home-features">

                <div className="serviceInfoWrapper">
                    <div className="serviceInfoText">
                        <h2 className="interested-heading">{servicesHeading}</h2>
                        <p className="parah2 mb50"
                           dangerouslySetInnerHTML={{ __html: servicesSubtitle.servicesSubtitle.replace(/\n/g, '<br/>') }}/>
                    </div>

                </div>
                <div className="rehab-process servicesOffer">
                    <div className="rehabProcessList">
                        {visibleServices && visibleServices.map((item, index) => {
                            return (
                                <a className="rehabProcessBox" href={"/" + item.slug}>
                                    <div key={item.name + index}>
                                        {isValidContentfulImage(
                                            item.serviceIcon) && (
                                            <img
                                                className="new-feature-image"
                                                src={item.serviceIcon.file.url}
                                                alt=" Frame"/>
                                        )}
                                        <div className="processName">{item.serviceName}</div>
                                        <div className="processDescription">{item.shortDescription}</div>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>

                <div className="serviceFeatureWrapper">
                    <div className="new-home-features-inner">
                        <div className="rehab-process">
                            {
                                processHeading && <h2 className="interested-heading mb100">{processHeading}</h2>
                            }
                            <div className="rehabProcessList">
                                {processItems && processItems.map((item, index) => {
                                    return (
                                        <div className="rehabProcessBox" key={item.name + index}>
                                            <span className="processNumber">{index + 1}</span>
                                            {isValidContentfulImage(
                                                item.image) && (
                                                <img
                                                    className="new-feature-image"
                                                    src={item.image.file.url}
                                                    alt=" Frame"/>
                                            )}
                                            <div className="processName">{item.name}</div>
                                            <div className="processDescription">{item.description}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {
                            belowProcessText && (
                                <div>
                                    <div className="something-main-wrapper">
                                        <div className="something-inner">
                                            <h2 className="something-head">{belowProcessText.belowProcessText}</h2>
                                            <Button className="blue-arrow-btn"
                                                    href="https://apps.apple.com/us/app/confidant-health/id1478727129">
                                                DOWNLOAD OUR APP <ArrowNavigationIcon/>
                                            </Button>

                                        </div>

                                    </div>
                                </div>
                            )
                        }

                        {
                            letterToLovedOnes && (
                                <div className="new-home-letter-one">
                                    <div className="new-home-letter-one-inner">
                                        {isValidContentfulImage(
                                            letterToLovedOnes.image) && (
                                            <img
                                                className="new-feature-image"
                                                src={letterToLovedOnes.image.file.url}
                                                alt=" Frame"/>
                                        )}
                                        <h2 className="home-letter-one-heading">{letterToLovedOnes.heading}</h2>
                                        <p className="home-letter-one-para">{letterToLovedOnes.description.description}</p>
                                        <Button href="/letter-to-loved-ones/"
                                                className="new-blue-link" color="primary">
                                            Read the Letter <ArrowForwardThinIcon/>
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                        <div className="faqWrapper">
                            {
                                faQsHeading !== null && <h2 className="interested-heading">{faQsHeading}</h2>
                            }
                            <div className="faqList">
                                {
                                    faqItems && faqItems.map((faqItem, index) => {
                                        const active = index === activeFaqIndex
                                        return (
                                            <div className="faqItems" key={"faq-item-" + index}>
                                                <Button
                                                    onClick={() => {
                                                        setActiveFaqIndex(index)
                                                    }}

                                                    className={active ? "new-blue-link-active" : "new-blue-link"}
                                                    color="primary">
                                                    {faqItem.title}
                                                </Button>
                                                {
                                                    active &&
                                                    <p className="parah"
                                                       dangerouslySetInnerHTML={{ __html: faqItem.description.description.replace(/\n/g, '<br/>') }}/>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export const query = graphql` 
query getServiceHubPage {
  allContentfulServiceArchivePage {
    edges {
      node {
        topSection {
          title
          subtitle
          description {
            description
          }
          imageOrVideo {
            file {
              url
            }
          }
        }
        servicesHeading
        servicesSubtitle {
          servicesSubtitle
        }
        visibleServices {
          slug
          serviceName
          shortDescription
          serviceIcon {
            file {
              url
            }
            gatsbyImageData
          }
        }
        processHeading
        processItems {
          description
          name
          image {
            gatsbyImageData
            file {
              url
            }
          }
        }
        belowProcessText {
          belowProcessText
        }
        letterToLovedOnes {
          description {
            description
          }
          heading
          image {
            title
            description
            file {
              url
            }
          }
        }
        faQsHeading
      faqItems {
        title
        description {
            description
        }
      }
      }
    }
  }
}

`


